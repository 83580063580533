import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-plano-texas.png'
import image0 from "../../images/cities/scale-model-of-downtown-plano-in-plano-texas.png"
import image1 from "../../images/cities/scale-model-of-heritage-farmstead-museum-in-plano-texas.png"
import image2 from "../../images/cities/scale-model-of-dark-hour-haunted-house-in-plano-texas.png"
import image3 from "../../images/cities/scale-model-of-addison-circle-park-in-plano-texas.png"
import image4 from "../../images/cities/scale-model-of-viva-tours-in-plano-texas.png"
import image5 from "../../images/cities/scale-model-of-wild-west-adventures-in-plano-texas.png"
import image6 from "../../images/cities/scale-model-of-toyota-tourist-in-plano-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Plano'
            state='Texas'
            image={image}
            lat='33.0508'
            lon='-96.7479'
            attractions={ [{"name": "Downtown Plano", "vicinity": "1037 E 15th St, Plano", "types": ["point_of_interest", "establishment"], "lat": 33.0198482, "lng": -96.69954999999999}, {"name": "Heritage Farmstead Museum", "vicinity": "1900 W 15th St, Plano", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.018865, "lng": -96.731065}, {"name": "Dark Hour Haunted House", "vicinity": "701 Taylor Dr, Plano", "types": ["point_of_interest", "establishment"], "lat": 33.0095482, "lng": -96.70437279999999}, {"name": "Addison Circle Park", "vicinity": "4970 Addison Cir, Addison", "types": ["park", "point_of_interest", "establishment"], "lat": 32.9612772, "lng": -96.8266835}, {"name": "Viva Tours", "vicinity": "6420 Camille Ave, Dallas", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.999826, "lng": -96.79182100000003}, {"name": "Wild West Adventures", "vicinity": "17610 Midway Rd, Dallas", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.9880794, "lng": -96.84087869999996}, {"name": "TOYOTA TOURIST", "vicinity": "5700 Legacy Dr A-4, Plano", "types": ["travel_agency", "finance", "point_of_interest", "establishment"], "lat": 33.0773572, "lng": -96.819456}] }
            attractionImages={ {"Downtown Plano":image0,"Heritage Farmstead Museum":image1,"Dark Hour Haunted House":image2,"Addison Circle Park":image3,"Viva Tours":image4,"Wild West Adventures":image5,"TOYOTA TOURIST":image6,} }
       />);
  }
}